import { FC } from 'react';

import classNames from 'classnames';

import { MQFormProviderProps } from '../../types';

const MQFormProvider: FC<MQFormProviderProps> = ({ className = '', children, ...props }) => (
  <form {...props} className={classNames(['mq-form', className])}>
    {children}
  </form>
);

export default MQFormProvider;
