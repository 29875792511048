import { default as Dots } from './components/MQLoaderDots';
import { default as Skeleton } from './components/MQLoaderSkeleton';
import { default as Spinner } from './components/MQLoaderSpinner';
import { default as Thinking } from './components/MQLoaderThinking';

const MQLoader = {
  Dots,
  Skeleton,
  Spinner,
  Thinking,
};
export default MQLoader;
