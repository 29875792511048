import { lazy } from 'react';

import ROUTES from 'routes';

const ApprovalRequest = lazy(() => import('./ApprovalRequest'));
const EnrollmentPreferences = lazy(() => import('./EnrollmentPreferences'));
const EnrollmentPreferencesSuccess = lazy(() => import('./EnrollmentPreferencesSuccess'));
const MatchComparison = lazy(() => import('./MatchComparison'));
const AcceptSelfMatchRequest = lazy(() => import('./MatchRequests/AcceptSelfMatchRequest'));
const DeclineSelfMatchRequest = lazy(() => import('./MatchRequests/DeclineSelfMatchRequest'));
const MatchRequestConfirmed = lazy(() => import('./MatchRequests/MatchRequestConfirmed'));
const MatchRequestDeclined = lazy(() => import('./MatchRequests/MatchRequestDeclined'));
const MatchRequestExpired = lazy(() => import('./MatchRequests/MatchRequestExpired'));
const NudgeLeaveRelationship = lazy(() => import('./NudgeLeaveRelationship'));
const Privacy = lazy(() => import('./Privacy'));
const StatusPage403 = lazy(() => import('./StatusPage403'));
const StatusPage404 = lazy(() => import('./StatusPage404'));
const StatusPage429 = lazy(() => import('./StatusPage429'));
const StatusPage500 = lazy(() => import('./StatusPage500'));
const Support = lazy(() => import('./Support'));
const CookiesPolicy = lazy(() => import('./CookiesPolicy'));

export const CommonPages = [
  {
    route: ROUTES.statusPage403.data,
    component: <StatusPage403 />,
  },
  {
    route: ROUTES.statusPage404.data,
    component: <StatusPage404 />,
  },
  {
    route: ROUTES.statusPage429.data,
    component: <StatusPage429 />,
  },
  {
    route: ROUTES.statusPage500.data,
    component: <StatusPage500 />,
  },
  {
    route: ROUTES.acceptMatchRequest.data,
    component: <AcceptSelfMatchRequest />,
  },
  {
    route: ROUTES.declineMatchRequest.data,
    component: <DeclineSelfMatchRequest />,
  },
  {
    route: ROUTES.matchRequestExpired.data,
    component: <MatchRequestExpired />,
  },
  {
    route: ROUTES.matchRequestDeclined.data,
    component: <MatchRequestDeclined />,
  },
  {
    route: ROUTES.matchRequestConfirmed.data,
    component: <MatchRequestConfirmed />,
  },
  {
    route: ROUTES.nudgeLeaveRelationship.data,
    component: <NudgeLeaveRelationship />,
  },
  {
    route: ROUTES.matchComparison.data,
    component: <MatchComparison />,
  },
  {
    route: ROUTES.privacy.data,
    component: <Privacy />,
  },
  {
    route: ROUTES.support.data,
    component: <Support />,
  },
  {
    route: ROUTES.enrollmentsRequestsEncrypted.data,
    component: <EnrollmentPreferences />,
  },
  {
    route: ROUTES.enrollmentsRequestsEncryptedSuccess.data,
    component: <EnrollmentPreferencesSuccess />,
  },
  {
    route: ROUTES.enrollmentApprovalRequest.data,
    component: <ApprovalRequest />,
  },
  {
    route: ROUTES.cookiesPolicy.data,
    component: <CookiesPolicy />,
  },
];
