import { useMemo } from 'react';

import { getBrandingLogoConfigsSelector } from '@mentorcliq/storage';

import { useAppSelector } from 'hooks/useAppSelector';
import { useAppViewport } from 'hooks/useAppViewport';

export const useAppBranding = () => {
  const { isTablet } = useAppViewport();
  const logoConfigs = useAppSelector(({ branding }) => getBrandingLogoConfigsSelector(branding));

  const logo = useMemo(() => {
    const branded = !!logoConfigs?.displayDefault;

    if (isTablet) {
      return {
        src: logoConfigs?.mobile?.fileHandle ?? logoConfigs?.main?.fileHandle ?? '',
        branded,
      };
    }

    return {
      src: logoConfigs?.main?.fileHandle ?? logoConfigs?.mobile?.fileHandle ?? '',
      branded,
    };
  }, [isTablet, logoConfigs?.displayDefault, logoConfigs?.main?.fileHandle, logoConfigs?.mobile?.fileHandle]);

  return useMemo(
    () => ({
      logo,
    }),
    [logo],
  );
};
