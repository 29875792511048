import './style.scss';

import { FC } from 'react';

import classNames from 'classnames';

import { MQFormInputProps } from '../../types';

const MQFormInput: FC<MQFormInputProps> = ({
  value,
  isValid,
  isInvalid,
  dataTestId,
  className,
  compact = false,
  instance,
  ariaLabel = 'Single Line String Field',
  ...props
}) => (
  <input
    {...props}
    value={value}
    ref={instance}
    data-testid={`mq-form-input-${dataTestId || props.name}`}
    aria-label={ariaLabel}
    className={classNames(['mq-form-input', { ['is-valid']: isValid, ['is-invalid']: isInvalid, compact }, className])}
  />
);

export default MQFormInput;
