import { FC, ReactNode, useMemo } from 'react';

import { MQIcon } from '@mentorcliq/ui';

import ROUTES from 'routes';

import NoResultFoundImage from 'assets/dynamic/noResultsFound';
import { MQ_RICH_FORMATING_ELEMENTS } from 'assets/elements';

import { APP_SUPPORT_EMAIL } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { openLink } from 'helpers/routes';

import MQButton from 'modules/MQButton';
import MQGrid from 'modules/MQGrid';
import MQLayout from 'modules/MQLayout';
import MQTypo from 'modules/MQTypo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface Props {
  label?: ReactNode;
  onClearFilter?: () => void;
  content?: ReactNode;
  title?: ReactNode;
  dirty?: boolean;
  fallback?: ReactNode;
  article?: string;
  support?: boolean;
  compact?: boolean;
  children?: ReactNode;
}

const EmptyList: FC<Props> = ({
  content,
  label,
  title,
  dirty = false,
  onClearFilter,
  fallback,
  article,
  support = false,
  compact = false,
  children,
}) => {
  const dataLabel = useMemo(
    () =>
      label || (
        <AppFormattedMessage
          defaultMessage="data"
          description="[Global] Empty list label"
          id="global.empty.list.label"
        />
      ),
    [label],
  );

  const sections = useMemo(
    () => ({
      icon: (
        <MQGrid.FlexBox justify="center" fill>
          <MQIcon.Custom svg={NoResultFoundImage} />
        </MQGrid.FlexBox>
      ),
      title: (
        <MQTypo.Formatter infoIcon>
          {title || (
            <AppFormattedMessage
              defaultMessage="No {label} found"
              description="[EmptyList] Heading"
              id="empty.list.heading"
              values={{
                label: dataLabel,
              }}
            />
          )}
        </MQTypo.Formatter>
      ),
      body:
        content ??
        (!!onClearFilter && (
          <>
            <MQTypo.Paragraph center={compact}>
              <AppFormattedMessage
                defaultMessage="You have no {label} to show based on your filter criteria."
                description="[EmptyList] Description"
                id="empty.list.description"
                values={{
                  label: dataLabel,
                }}
              />
            </MQTypo.Paragraph>
            <MQTypo.Paragraph center={compact}>
              <AppFormattedMessage
                defaultMessage="You may <button>clear your filters</button> to view all entries."
                description="[EmptyList] Clear filters button"
                id="empty.list.clear.filters.button"
                values={{
                  button: MQ_RICH_FORMATING_ELEMENTS.clearFilters({
                    testId: 'empty-list-clear-filters',
                    callback: onClearFilter,
                  }),
                }}
              />
            </MQTypo.Paragraph>
          </>
        )),
      footer: (
        <>
          {children}
          {support && (
            <MQButton
              dataTestId="contact-support"
              onClick={() =>
                openLink({
                  email: APP_SUPPORT_EMAIL,
                })
              }
              startIcon={<MQIcon.Svg icon="user-headset" />}
            >
              <AppFormattedMessage
                defaultMessage="Contact Support"
                description="[EmptyList] Contact support button label"
                id="empty.list.contact.button"
              />
            </MQButton>
          )}
          {article && (
            <MQButton
              dataTestId="learn-more"
              variant="tertiary"
              startIcon={<MQIcon.Svg icon="info-circle__r" />}
              onClick={() =>
                openLink({
                  url: ROUTES.cliqipedia.convert({
                    search: {
                      article,
                    },
                  }),
                })
              }
            >
              <AppFormattedMessage {...APP_GLOBAL_MESSAGES.learnMore} />
            </MQButton>
          )}
        </>
      ),
    }),
    [article, compact, children, content, dataLabel, onClearFilter, support, title],
  );

  if (fallback && !dirty) {
    return <>{fallback}</>;
  }

  return (
    <MQGrid.FlexBox className="empty-list" justify="center" align="center" fill>
      {compact ? (
        <MQGrid.Row centered>
          <MQGrid.Col xl={12} lg={12} md={12} sm={12}>
            {sections.icon}
          </MQGrid.Col>
          <MQGrid.Col xl="auto" lg="auto" md="auto" sm={12}>
            <MQGrid.FlexBox direction="column" justify="center" align="center">
              <MQLayout.Heading dataTestId="empty-list-no-items" center>
                <MQTypo.Text size="5x" bold nowrap center>
                  {sections.title}
                </MQTypo.Text>
              </MQLayout.Heading>
              {sections.body}
              {sections.footer}
            </MQGrid.FlexBox>
          </MQGrid.Col>
        </MQGrid.Row>
      ) : (
        <MQGrid.Row>
          <MQGrid.Col xl="auto" lg="auto" md="auto" sm={12}>
            {sections.icon}
          </MQGrid.Col>
          <MQGrid.Col xl="auto" lg="auto" md="auto" sm={12}>
            <MQGrid.FlexBox direction="column">
              <MQLayout.Heading dataTestId="empty-list-no-items">
                <MQTypo.Text size="5x" bold nowrap>
                  {sections.title}
                </MQTypo.Text>
              </MQLayout.Heading>
              {sections.body}
              <MQGrid.FlexBox>{sections.footer}</MQGrid.FlexBox>
            </MQGrid.FlexBox>
          </MQGrid.Col>
        </MQGrid.Row>
      )}
    </MQGrid.FlexBox>
  );
};

export default EmptyList;
