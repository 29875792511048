import { defineMessage } from 'react-intl';

export const LOADING_MESSAGES = [
  defineMessage({
    defaultMessage: 'Thinking about your question...',
    description: '[CliqAi] Thinking about your question loading message',
    id: 'cliq.ai.loading.message.1',
  }),
  defineMessage({
    defaultMessage: 'Analyzing the details...',
    description: '[CliqAi] Analyzing the details loading message',
    id: 'cliq.ai.loading.message.2',
  }),
  defineMessage({
    defaultMessage: 'Searching for the best answer...',
    description: '[CliqAi] Searching for the best answer loading message',
    id: 'cliq.ai.loading.message.3',
  }),
  defineMessage({
    defaultMessage: 'Compiling information...',
    description: '[CliqAi] Compiling information loading message',
    id: 'cliq.ai.loading.message.4',
  }),
  defineMessage({
    defaultMessage: 'Let me look into that...',
    description: '[CliqAi] Let me look into that loading message',
    id: 'cliq.ai.loading.message.5',
  }),
  defineMessage({
    defaultMessage: 'Processing your request...',
    description: '[CliqAi] Processing your request loading message',
    id: 'cliq.ai.loading.message.6',
  }),
  defineMessage({
    defaultMessage: 'Evaluating the options...',
    description: '[CliqAi] Evaluating the options loading message',
    id: 'cliq.ai.loading.message.7',
  }),
  defineMessage({
    defaultMessage: 'Gathering relevant data...',
    description: '[CliqAi] Gathering relevant data loading message',
    id: 'cliq.ai.loading.message.8',
  }),
  defineMessage({
    defaultMessage: 'Delving into the specifics...',
    description: '[CliqAi] Delving into the specifics loading message',
    id: 'cliq.ai.loading.message.9',
  }),
  defineMessage({
    defaultMessage: 'Breaking it down...',
    description: '[CliqAi] Breaking it down loading message',
    id: 'cliq.ai.loading.message.10',
  }),
  defineMessage({
    defaultMessage: 'Exploring the possibilities...',
    description: '[CliqAi] Exploring the possibilities loading message',
    id: 'cliq.ai.loading.message.11',
  }),
  defineMessage({
    defaultMessage: 'Reviewing the facts...',
    description: '[CliqAi] Reviewing the facts loading message',
    id: 'cliq.ai.loading.message.12',
  }),
  defineMessage({
    defaultMessage: 'Checking the details for you...',
    description: '[CliqAi] Checking the details for you loading message',
    id: 'cliq.ai.loading.message.13',
  }),
  defineMessage({
    defaultMessage: 'Running a quick analysis...',
    description: '[CliqAi] Running a quick analysis loading message',
    id: 'cliq.ai.loading.message.14',
  }),
  defineMessage({
    defaultMessage: 'Sifting through the information...',
    description: '[CliqAi] Sifting through the information loading message',
    id: 'cliq.ai.loading.message.15',
  }),
  defineMessage({
    defaultMessage: 'Consulting my knowledge base...',
    description: '[CliqAi] Consulting my knowledge base loading message',
    id: 'cliq.ai.loading.message.16',
  }),
  defineMessage({
    defaultMessage: 'Digging deeper for clarity...',
    description: '[CliqAi] Digging deeper for clarity loading message',
    id: 'cliq.ai.loading.message.17',
  }),
  defineMessage({
    defaultMessage: 'Cross-referencing the info...',
    description: '[CliqAi] Cross-referencing the info loading message',
    id: 'cliq.ai.loading.message.18',
  }),
  defineMessage({
    defaultMessage: 'Formulating the best response...',
    description: '[CliqAi] Formulating the best response loading message',
    id: 'cliq.ai.loading.message.19',
  }),
  defineMessage({
    defaultMessage: 'Combining all the insights...',
    description: '[CliqAi] Combining all the insights loading message',
    id: 'cliq.ai.loading.message.20',
  }),
  defineMessage({
    defaultMessage: 'Clarifying the key points...',
    description: '[CliqAi] Clarifying the key points loading message',
    id: 'cliq.ai.loading.message.21',
  }),
  defineMessage({
    defaultMessage: 'Synthesizing the information...',
    description: '[CliqAi] Synthesizing the information loading message',
    id: 'cliq.ai.loading.message.22',
  }),
];
