import './App.scss';

import { FC } from 'react';

import { Provider } from 'react-redux';

import storage from 'storage';

import history from 'helpers/history';

import AppRoute from 'router/AppRoute';
import AppRouter from 'router/AppRouter';
import AppSwitch from 'router/AppSwitch';

import AppLayout from 'layout/AppLayout';

import AppWrapper from 'components/AppWrapper';

import AppProvider from 'providers/AppProvider';
import ErrorProvider from 'providers/ErrorProvider';
import LayoutProvider from 'providers/LayoutProvider';
import PageProvider from 'providers/PageProvider';
import RouteProvider from 'providers/RouteProvider';
import VerifyProvider from 'providers/VerifyProvider';

import { CommonPages } from 'pages/common';
import { PreviewPages } from 'pages/preview';
import { PrivatePages } from 'pages/private';
import { PublicPages } from 'pages/public';

const App: FC = () => (
  <ErrorProvider>
    <Provider store={storage.mq}>
      <AppProvider>
        <AppRouter history={history.mq}>
          <AppWrapper>
            <VerifyProvider>
              <RouteProvider>
                <PageProvider>
                  <LayoutProvider>
                    <AppLayout>
                      <AppSwitch>
                        {[...CommonPages, ...PrivatePages, ...PublicPages, ...PreviewPages].map((page) => (
                          <AppRoute key={page.route.path} {...page.route}>
                            {page.component}
                          </AppRoute>
                        ))}
                      </AppSwitch>
                    </AppLayout>
                  </LayoutProvider>
                </PageProvider>
              </RouteProvider>
            </VerifyProvider>
          </AppWrapper>
        </AppRouter>
      </AppProvider>
    </Provider>
  </ErrorProvider>
);

export default App;
