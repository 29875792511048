import './styles.scss';

import { FC } from 'react';

import { MQIcon } from '@mentorcliq/ui';
import { format } from 'date-fns';

import { TIME_HOURS_MINUTES_WITH_AM_PM } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQLayout from 'modules/MQLayout';
import MQLoader from 'modules/MQLoader';
import MQTypo from 'modules/MQTypo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import { LOADING_MESSAGES } from '../../definitions';

const CliqAiLoadingMessage: FC = () => (
  <div className="cliq-ai-loading-message">
    <MQLayout.Heading
      variant="neutral"
      info={<MQTypo.Caption>{format(Date.now(), TIME_HOURS_MINUTES_WITH_AM_PM)}</MQTypo.Caption>}
    >
      <MQTypo.Formatter
        prefix={
          <div className="cliq-ai-loading-message__icon">
            <MQIcon.Svg size="lg" icon="sparkles" />
          </div>
        }
      >
        <MQTypo.Text size="3x" bold nowrap>
          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cliqAiLabel} />
        </MQTypo.Text>
      </MQTypo.Formatter>
    </MQLayout.Heading>
    <MQLoader.Thinking messages={LOADING_MESSAGES.map((message) => message.defaultMessage)} />
  </div>
);

export default CliqAiLoadingMessage;
