import './style.scss';

import { FC, useEffect, useRef, useState } from 'react';

import { clearAiHistoryThunk } from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppDispatch } from 'hooks/useAppDispatch';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import ChatWindow from './Addons/components/ChatWindow';
import WelcomeWindow from './Addons/components/WelcomeWindow';

enum CliqAiStepsEnum {
  Welcome = 'WELCOME',
  Chat = 'CHAT',
}

const AppCliqAi: FC = () => {
  const dispatch = useAppDispatch();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [showContent, setShowContent] = useState(false);
  const [step, setStep] = useState<CliqAiStepsEnum>(CliqAiStepsEnum.Welcome);

  useEffect(() => {
    const handleBeforeUnload = async () => {
      await dispatch(clearAiHistoryThunk());
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        setShowContent(!showContent);
      }
    };

    if (showContent) {
      document.addEventListener('mouseup', handleClickOutside);
    }
  }, [showContent]);

  return (
    <div className="app-cliq-ai" ref={dialogRef}>
      <button
        data-testid="cliq-ai-button"
        aria-label="Cliq AI button"
        className={classNames('app-cliq-ai__button', { initial: !showContent })}
        onClick={() => setShowContent(!showContent)}
      >
        {showContent ? <MQIcon.Svg size="lg" icon="times__l" /> : <MQIcon.Svg size="lg" icon="sparkles" />}
        {!showContent && (
          <div className="app-cliq-ai__button-label">
            <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cliqAiLabel} />
          </div>
        )}
      </button>
      {showContent && (
        <div>
          {step === CliqAiStepsEnum.Welcome ? (
            <WelcomeWindow
              next={() => {
                setStep(CliqAiStepsEnum.Chat);
              }}
            />
          ) : (
            <ChatWindow
              back={() => {
                setStep(CliqAiStepsEnum.Welcome);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AppCliqAi;
