import { useEffect, useState, useCallback } from 'react';

import isMobile from 'ismobilejs';

const MOBILE_WIDTH = 768;

export const useAppViewport = ({ width = MOBILE_WIDTH } = {}) => {
  const [state, setState] = useState({
    isMobile: global.window.innerWidth <= width || isMobile().phone,
    isTablet: global.window.innerWidth <= width || isMobile().tablet,
  });

  const handleResize = useCallback(() => {
    setState({
      isMobile: global.window.innerWidth <= width || isMobile().phone,
      isTablet: global.window.innerWidth <= width || isMobile().tablet,
    });
  }, [width]);

  useEffect(() => {
    global.window.addEventListener('resize', handleResize);

    return () => {
      global.window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return state;
};
