import commonRoutes from 'routes/common';
import privateRoutes from 'routes/private';
import publicRoutes from 'routes/public';

import { createRoute } from 'helpers/routes';

export default {
  redirectLogin: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/logout`,
    },
    redirectTo: {
      path: publicRoutes.login.data.path,
      exact: true,
    },
  }),
  redirectEnrollmentsRequestsEncrypted: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/preferences/:encryptedOldEnrollmentId`,
    },
    redirectTo: {
      path: commonRoutes.enrollmentsRequestsEncrypted.data.path,
    },
  }),
  redirectMainPage: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/user',
    },
    redirectTo: {
      path: privateRoutes.myCliQ.data.path,
    },
  }),
  redirectResetPassword: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/user/reset/:oldUserId/:timestamp/:token',
      exact: true,
    },
    redirectTo: {
      path: publicRoutes.createPassword.data.path,
      exact: true,
    },
  }),
  redirectGroupComparison: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/group-comparison/:oldProgramId/:oldGroupId/-1',
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.matchComparison.data.path,
      exact: true,
    },
  }),
  redirectMatchComparison: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: '/match-comparison/:oldProgramId/:oldEnrollmentId1/:oldEnrollmentId2',
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.matchComparison.data.path,
      exact: true,
    },
  }),
  redirectQuickcliQRequestsEncrypted: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/quickcliq/:encryptedOldQQRequestId`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.quickcliQRequests.data.path,
      exact: true,
    },
  }),
  redirectNudgeLeaveRelationship: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/group-leave/:encryptedOldLeaveData`,
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.nudgeLeaveRelationship.data.path,
      exact: true,
    },
  }),
  redirectTimeline: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/timeline/:oldUserId`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.timeline.data.path,
      exact: true,
    },
  }),
  redirectSurveyForm: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/custom-survey/:encryptedOldSurveyRequestId`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.surveyRequests.data.path,
      exact: true,
    },
  }),
  redirectSatisfactionSurveyForm: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/satisfaction-survey/:encryptedOldSatisfactionSurveyRequestId`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.surveyRequests.data.path,
      exact: true,
    },
  }),
  redirectAcceptMatchRequest: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/match-request/:encryptedOldGroupId/accept`,
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.acceptMatchRequest.data.path,
      exact: true,
    },
  }),
  redirectDeclineMatchRequest: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/match-request/:encryptedOldGroupId/decline`,
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.declineMatchRequest.data.path,
      exact: true,
    },
  }),
  redirectEnrollmentApprovalRequest: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/enrollment_approval/:encryptedOldEnrollmentId`,
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.enrollmentApprovalRequest.data.path,
      exact: true,
    },
  }),
  redirectPrograms: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/open-programs/:oldUserId`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.programs.data.path,
      exact: true,
    },
  }),
  redirectEnrollPreferences: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/welcome/preferences/:enrollmentId`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.myCliQ.data.path,
    },
  }),
  redirectEnrollWelcome: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/enroll/:programAlias`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.enrollWizardWelcome.data.path,
      exact: true,
    },
  }),
  redirectWelcome: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/welcome`,
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.mainPage.data.path,
      exact: true,
    },
  }),
  redirectEnrollSRWelcome: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/enroll/welcome/:programId`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.enrollWizardWelcome.data.path,
      exact: true,
    },
  }),
  redirectDevelopmentReportsPersonal: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/report/:oldUserId/development/personal`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.developmentReportsPersonal.data.path,
      exact: true,
    },
  }),
  redirectDevelopmentReportsOthers: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/report/:oldUserId/development`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.developmentReportsOthers.data.path,
      exact: true,
    },
  }),
  redirectMilestones: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/user/:oldUserId/programs`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.timeline.data.path,
      exact: true,
    },
  }),
  redirectLearningLabs: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/:oldLabType/:oldSessionType/live`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.learningLabLive.data.path,
      exact: true,
    },
  }),
  redirectMentorLab: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/mentorlab`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.mentorLab.data.path,
      exact: true,
    },
  }),
  redirectInclusionLab: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/inclusionlab`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.inclusionLab.data.path,
      exact: true,
    },
  }),
  redirectLeadershipLab: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/leadershiplab`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.leadershipLab.data.path,
      exact: true,
    },
  }),
  redirectLearningLabList: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/learninglab`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.labsList.data.path,
      exact: true,
    },
  }),
  redirectProgramResources: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/node/:oldProgramId/program-resources-dashboard`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.resourceLibraryProgram.data.path,
      exact: true,
    },
  }),
  redirectGlobalResourcesModal: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/resource-library`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.resourceLibraryGlobal.data.path,
      exact: true,
    },
  }),
  redirectProgramResourcesModal: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/program-resource-library`,
      exact: true,
    },
    redirectTo: {
      path: privateRoutes.resourceLibraryProgram.data.path,
      exact: true,
    },
  }),
  redirectHome: createRoute({
    params: {
      layoutTypes: [],
      types: [],
    },
    data: {
      path: `/home`,
      exact: true,
    },
    redirectTo: {
      path: commonRoutes.mainPage.data.path,
      exact: true,
    },
  }),
};
