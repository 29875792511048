export const PAGEABLE_MAX_SIZE = 200;
export const PAGEABLE_SIZE = 25;
export const PAGEABLE_ODD_SIZE = 12;
export const PAGEABLE_MID_SIZE = 50;
export const PAGEABLE_SMALL_SIZE = 10;
export const TIME_MINUTES_SECONDS = 'mm:ss';
export const TIME_24_HOURS_MINUTES_FORMAT = 'HH:mm';
export const TIME_HOURS_MINUTES_WITH_AM_PM = 'hh:mm a';
export const LOCALE_DATE_FORMAT = 'yyyy-MM-dd';
export const LOCALE_DATE_FORMAT_MONTH_O_NAME_DAY = 'MMMM do';
export const LOCALE_DATE_FORMAT_WITH_MONTH_WEEK_DAY = 'EEEE, MMMM do';
export const LOCALE_DATE_FORMAT_MONTH_DAY_ONLY = 'MMM dd';
export const LOCALE_DATE_FORMAT_WITH_MONTH = 'MMMM d, yyyy';
export const LOCALE_DATE_FORMAT_WITH_MONTH_O = 'MMMM do, yyyy';
export const LOCALE_DATE_FORMAT_WITH_MONTH_ONLY = 'MMMM, yyyy';
export const LOCALE_DATE_FORMAT_WITH_MONTH_SHORT = 'MMM - yyyy';
export const LOCALE_DATE_TIME_FORMAT_WITH_MONTH = 'MMM d, yyyy - HH:mm';
export const LOCALE_DATE_TIME_FORMAT_WITH_MONTH_DATETIME = 'MMM d, yyyy - HH:mm a';
export const LOCALE_DATE_TIME_FORMAT_WITH_MONTH_NO_COMMA = 'MMM d yyyy - HH:mm';
export const LOCALE_DATE_TIME_FORMAT_WITH_WEEKDAY_WITH_COMMAS = 'EEEE, MMM d, yyyy - HH:mm';
export const LOCALE_DATE_TIME_FORMAT_WITH_FULL_WEEKDAY_WITH_COMMAS = 'EEEE, MMMM d, yyyy - HH:mm';
export const LOCALE_DATE_12_HOURS_TIME_FORMAT_WITH_WEEKDAY = 'EEEE, MMMM d yyyy hh:mm aaa';

export const LOCALE_DATE_FORMAT_LONG_MONTH_AND_YEAR = 'MMMM yyyy';
export const LOCALE_DATE_FORMAT_MONTH_AND_YEAR = 'MMM yyyy';
export const LOCALE_DATE_FORMAT_MONTH = 'MMM';
export const LOCALE_DATE_FORMAT_O_DAY_NAME = 'do';

export const LOCALE_DATE_TIME_FULL_FORMAT = 'MMM d, yyyy, HH:mm:ss a';

export const MONTH_NAME_DAY_YEAR_FORMAT = 'MMM dd, yyyy';
export const LOCALE_DATE_FORMAT_MONTH_DAY_YEAR = 'MMM d, yyyy';
export const LOCALE_DATE_FORMAT_WITH_TIME = 'MMM d, yyyy hh:mm a';

export const ISO_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.sss'Z'";

export const USER_BADGE_MAX_LENGTH = 20;
export const BADGE_LABEL_MAX_LENGTH = 20;

export const USER_INFO_FILE_MAX_LENGTH = 40;
export const FILTER_DEBOUNCE_TIME = 700;
export const APP_DEBOUNCE_TIME = 700;

export const URL_PATTERN = `(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})`;
export const EMAIL_PATTERN = `^[a-zA-Z0-9][a-zA-Z0-9-+_\\.]+@([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\\.[a-zA-Z0-9]{2,10}(?:\\.[a-zA-Z]{2,10})?$`;
export const HTML_PATTERN = '<[^>]';
export const INTERNATIONAL_REGION_CODE = 'IN';

export const APP_SUPPORT_EMAIL = 'support@mentorcliq.com';
export const APP_PRIVACY_EMAIL = 'privacy@mentorcliq.com';
export const APP_EMAIL_SUFFIX = '@mentorcliq.com';

export const APP_ENV_CONFIGS = {
  projectId: process.env.REACT_APP_PROJECT_ID,
  projectEnv: process.env.REACT_APP_PROJECT_ENV,
  version: process.env.REACT_APP_PROJECT_VERSION,
  projectLocale: 'en',
  defaultVersion: 'latest',
  supportPath: process.env.REACT_APP_SUPPORT_PATH,
  supportArticlesPath: process.env.REACT_APP_ARTICLE_PATH,
  supportWidgetSrc: process.env.REACT_APP_WIDGET_SRC,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  projectApiHost: process.env.REACT_APP_API_HOST,
  projectApiUrl: process.env.REACT_APP_API_URL,
  projectApiVersion: process.env.REACT_APP_API_VERSION,
  projectFilesPath: process.env.REACT_APP_API_FILES,
  projectResourcesPath: process.env.REACT_APP_API_RESOURCES,
  appStaticAssetsSrc: process.env.REACT_APP_ASSETS_URL,
  bugSnagApiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  translatorLogsEnabled: process.env.REACT_APP_TRANSLATOR_LOGS_ENABLED,
  ipInfoToken: process.env.REACT_APP_IaP_INFO_TOKEN,
  environments: {
    production: process.env.NODE_ENV === 'production',
  },
};

export const APP_STATIC_LINKS = {
  mentorComEventsURL: 'https://www.mentorcliq.com/mentorcom-events',
  microsoftTeamsURL: 'teams.microsoft.com',
  msTeamsMentorcliQ: 'https://appsource.microsoft.com/en-us/product/office/WA200005867?tab=Overview',
  linkedinURL: 'www.linkedin.com',
  advancedFiltersVideo: 'https://vimeo.com/993803508/4920dc3730?share=copy',
  googleAuth: `${APP_ENV_CONFIGS.projectApiHost}/oauth2/authorization/google`,
  ciscoWebexConfig: `webexteams://im?email={email}?chat`,
  slackAuthUrl: `slack://user?team={teamId}&id={id}`,
  skypeChat: `skype:{skype}?chat`,
  teamsChat: `https://{url}/l/chat/0/0?users={users}&topicName=chat`,
};

export const APP_KEYBOARD_EVENT_KEYS = {
  enter: ['Enter'],
  space: [' ', 'Enter'],
};

export const SUGGESTIONS_MAX_COUNT = 3;

export const MIN_NUMBER_VALUE = -2147483648;
export const MAX_NUMBER_VALUE = 2147483647;

export const EMAIL_NAME_REGEXP = new RegExp(/^(\S)[\w\s.%'+-]+@[\w.-]+\.[a-zA-Z]{1,62}(\S)$/);
export const EMAILS_SEPARATOR_REGEXP = new RegExp(/[;,\n]\s*/g);

export const API_TIMOUT_MS = 29000;

export const APP_POINTERS = {
  uuid: 0,
};
