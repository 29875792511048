import { createRoot } from 'react-dom/client';

import App from './App';

const node = document.getElementById('root');

if (!node) {
  throw new Error('Root container missing in public/index.html');
}

const root = createRoot(node);

root.render(<App />);
