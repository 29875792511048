import { RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  files: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Authenticated],
    },
    data: {
      path: `/files`,
    },
  }),
  resourceLibraryFile: createRoute({
    params: {
      layoutTypes: [],
      types: [RouteTypes.Authenticated],
    },
    data: {
      exact: true,
      path: `/files/resource-library/:fileId/:fileName`,
      extraPath: `/files/resource-library`,
    },
  }),
};
