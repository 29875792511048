import { FC, ReactNode, useMemo, useState } from 'react';

import { LayoutSizeTypes, LayoutTypesEnum } from 'types/route';

import { APP_LAYOUT_CONTEXT_INITIAL_DATA, AppLayoutContext } from 'definitions/context';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppQuery } from 'hooks/useAppQuery';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppSocket } from 'hooks/useAppSocket';

interface LayoutProviderProps {
  children?: ReactNode;
}

const LayoutProvider: FC<LayoutProviderProps> = ({ children }) => {
  const { route } = useAppRouter();
  const { cliqipedia } = useAppQuery();
  const { user, profile, mode } = useAppAuthState();
  const { chatConfigs } = useAppSocket();
  const { publicConfigs } = useAppConfigs();
  const [layoutBanner, setLayoutBanner] = useState(APP_LAYOUT_CONTEXT_INITIAL_DATA.layoutBanner);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  const layoutSize = useMemo(() => route.params.layoutSize || LayoutSizeTypes.md, [route.params.layoutSize]);

  const showHeader = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Header) && !!user?.id,
    [route.params.layoutTypes, user?.id],
  );

  const showSidebar = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Sidebar) && mode.admin,
    [route.params.layoutTypes, mode.admin],
  );

  const showScroller = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Scroll),
    [route.params.layoutTypes],
  );

  const showViewAs = useMemo(() => mode.view, [mode.view]);

  const showFooter = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Footer),
    [route.params.layoutTypes],
  );

  const showAlerts = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Alerts),
    [route.params.layoutTypes],
  );

  const showBanner = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Banner) && !!layoutBanner.node,
    [route.params.layoutTypes, layoutBanner.node],
  );

  const showSupport = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Support) && !!user?.id && !cliqipedia && !!profile?.userId,
    [route.params.layoutTypes, user?.id, cliqipedia, profile?.userId],
  );

  const showChat = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Chat) && chatConfigs.active,
    [route.params.layoutTypes, chatConfigs.active],
  );

  const showCookies = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.Cookies) && publicConfigs.showCookiesBanner,
    [route.params.layoutTypes, publicConfigs.showCookiesBanner],
  );

  const showCliqAi = useMemo(
    () => route.params.layoutTypes.includes(LayoutTypesEnum.CliqAi) && publicConfigs.isCliqAiEnabled,
    [route.params.layoutTypes, publicConfigs.isCliqAiEnabled],
  );

  const context = useMemo(
    () => ({
      wrapperId: APP_LAYOUT_CONTEXT_INITIAL_DATA.wrapperId,
      sidebarSize: APP_LAYOUT_CONTEXT_INITIAL_DATA.sidebarSize,
      layoutSize,
      sidebarExpanded,
      setSidebarExpanded,
      layoutBanner,
      setLayoutBanner,
      showCookies,
      showChat,
      showSupport,
      showBanner,
      showAlerts,
      showFooter,
      showViewAs,
      showScroller,
      showSidebar,
      showHeader,
      showCliqAi,
    }),
    [
      layoutBanner,
      layoutSize,
      showAlerts,
      showBanner,
      showChat,
      showCookies,
      showFooter,
      showHeader,
      showScroller,
      showSidebar,
      showSupport,
      showViewAs,
      sidebarExpanded,
      showCliqAi,
    ],
  );

  return <AppLayoutContext.Provider value={context}>{children}</AppLayoutContext.Provider>;
};

export default LayoutProvider;
