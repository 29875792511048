import { FeatureTypeEnum } from '@mentorcliq/storage';

import { LayoutTypesEnum, RouteTypes } from 'types/route';

import { createRoute } from 'helpers/routes';

export default {
  acceptGdpr: createRoute({
    params: {
      layoutTypes: [
        LayoutTypesEnum.Footer,
        LayoutTypesEnum.Header,
        LayoutTypesEnum.Alerts,
        LayoutTypesEnum.Support,
        LayoutTypesEnum.Cookies,
        LayoutTypesEnum.CliqAi,
      ],
      types: [RouteTypes.Authenticated, RouteTypes.GdprAccepted],
      access: {
        features: {
          value: [FeatureTypeEnum.Gdpr],
        },
      },
    },
    data: {
      path: '/accept-gdpr',
    },
  }),
};
