import './style.scss';

import { FC, useMemo } from 'react';

import { MQAnimate, MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';

import ROUTES from 'routes';

import { useAppLayout } from 'hooks/useAppLayout';
import { useAppRouter } from 'hooks/useAppRouter';

import MQButton from 'modules/MQButton';
import MQSidebar from 'modules/MQSidebar';
import MQTooltip from 'modules/MQTooltip';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import Dashboard from './Dashboard';
import Platform from './Platform';
import Preview from './Preview';
import Program from './Program';
import Reports from './Reports';

const SIDEBARS = [
  {
    component: <Platform />,
    paths: [ROUTES.configure.data.path],
  },
  {
    component: <Reports />,
    paths: [ROUTES.reports.data.path],
  },
  {
    component: <Program />,
    paths: [ROUTES.program.data.path, ROUTES.programWizardDraftEdit.data.path],
  },
  {
    component: <Preview />,
    paths: [ROUTES.previewMainPage.data.path],
  },
  {
    component: <Dashboard />,
    paths: [ROUTES.mainPage.data.path],
  },
];

interface Props {
  expanded: boolean;
}

const AppSidebar: FC<Props> = ({ expanded }) => {
  const { route } = useAppRouter();
  const { setSidebarExpanded } = useAppLayout();

  const sidebar = useMemo(
    () => SIDEBARS.find((item) => item.paths.some((path) => route.data.path.includes(path))),
    [route],
  );

  return (
    <nav className={classNames(['app-sidebar', { expanded }])} aria-label="app-sidebar">
      <div className="app-sidebar__toggle">
        <MQTooltip
          placement="right"
          overlay={
            expanded ? (
              <AppFormattedMessage
                defaultMessage="Collapse"
                description="[AppSidebar] Collapse tooltip"
                id="app.sidebar.collapse"
              />
            ) : (
              <AppFormattedMessage
                defaultMessage="Expand"
                description="[Expand] Expand tooltip"
                id="app.sidebar.expand"
              />
            )
          }
          triggers={['hover']}
        >
          <MQButton
            dataTestId="sidebar-toggle"
            className="app-sidebar__toggle-button"
            variant="tertiary"
            shape="circle"
            onClick={() => {
              setSidebarExpanded?.(!expanded);
            }}
            startIcon={expanded ? <MQIcon.Svg icon="minus" /> : <MQIcon.Svg icon="plus" />}
          />
        </MQTooltip>
      </div>
      <MQAnimate.Styles animation="slide-right" dependencies={sidebar?.paths}>
        <MQSidebar className="app-sidebar__nav">{sidebar?.component}</MQSidebar>
      </MQAnimate.Styles>
    </nav>
  );
};

export default AppSidebar;
