import { FC } from 'react';

import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { ProviderTypesEnum } from 'types/access';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQSidebar from 'modules/MQSidebar';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

const Reports: FC = () => (
  <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-program">
    <MQSidebar.ListItem
      as={AppLink}
      data-testid="reports-sidebar-dashboard"
      className="app-sidebar__list-item"
      to={ROUTES.adminDashboard.data.path}
      prefix={<MQIcon.Svg icon="long-arrow-alt-left" />}
    >
      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.adminDashboard} />
    </MQSidebar.ListItem>

    <MQSidebar.ListItem>
      <MQSidebar.List className="app-sidebar__list">
        <MQSidebar.ListItem className="app-sidebar__list-item" data-testid="sidebar-reports">
          <MQSidebar.Heading className="app-sidebar__heading">
            <AppFormattedMessage
              defaultMessage="REPORTS"
              description="[AppSidebar.Reports] REPORTS heading label"
              id="app.sidebar.reports.heading.label"
            />
          </MQSidebar.Heading>
        </MQSidebar.ListItem>
        <PermissionWrapper
          features={{ value: [FeatureTypeEnum.RoiReport] }}
          permissions={{ value: [PermissionsEnum.RoiCalculator] }}
          strict
        >
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-roi-calculator-link"
            to={ROUTES.reportsROICalculator.data.path}
            prefix={<MQIcon.Svg icon="chart-pie__r" />}
          >
            <AppFormattedMessage
              defaultMessage="ROI Calculator"
              description="[AppSidebar.Reports] ROI Calculator item label"
              id="app.sidebar.reports.roi.calculator"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper
          permissions={{ value: [PermissionsEnum.ViewRoiTrackingResults] }}
          features={{ value: [FeatureTypeEnum.RoiReport] }}
          strict
        >
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-roi-tracking-report-link"
            to={ROUTES.roiTrackingReport.data.path}
            prefix={<MQIcon.Svg icon="file-chart-column__r" />}
          >
            <AppFormattedMessage
              defaultMessage="ROI Tracking Report"
              description="[AppSidebar.Reports] ROI Tracking Report item label"
              id="app.sidebar.reports.roi.tracking.report"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper
          permissions={{ value: [PermissionsEnum.ViewLearningLabReport] }}
          providers={{ value: [ProviderTypesEnum.LabsEnabled] }}
          strict
        >
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-mentor-lab-report-link"
            to={ROUTES.learningLabReport.data.path}
            prefix={<MQIcon.Svg icon="screen-users__r" />}
          >
            <AppFormattedMessage
              defaultMessage="LearningLab Report"
              description="[AppSidebar.Reports] LearningLab Report item label"
              id="app.sidebar.reports.learninglab.report"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageCustomerUsers] }}>
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-people-tracking-report-link"
            to={ROUTES.peopleReport.data.path}
            prefix={<MQIcon.Svg icon="user-group__r" />}
          >
            <AppFormattedMessage
              defaultMessage="People Tracking Report"
              description="[Sidebar] People Tracking Report Menu Item Name"
              id="sidebar.people.tracking.report.menu.item.name"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper permissions={{ value: [PermissionsEnum.ExportPlatformData] }}>
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-platform-report-link"
            to={ROUTES.platformReports.data.path}
            prefix={<MQIcon.Svg icon="file-export__r" />}
          >
            <AppFormattedMessage
              defaultMessage="Export Data"
              description="[AppSidebar.Reports] Export Data item label"
              id="app.sidebar.reports.export.data"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <MQSidebar.ListItem
          as={AppLink}
          className="app-sidebar__list-item"
          data-testid="sidebar-mentor-relationship-exception-report-link"
          to={ROUTES.platformRelationshipExceptionReport.data.path}
          prefix={<MQIcon.Svg icon="user-xmark__r" />}
        >
          <AppFormattedMessage
            defaultMessage="Relationship Exception Report"
            description="[Sidebar] Menu Item Name"
            id="sidebar.reports.relationship.exception.link"
          />
        </MQSidebar.ListItem>
        <PermissionWrapper
          features={{ value: [FeatureTypeEnum.ResourceLibrary] }}
          permissions={{ value: [PermissionsEnum.ManagePlatformResources] }}
          strict
        >
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-resource-library-report-link"
            to={ROUTES.resourceLibraryReport.data.path}
            prefix={<MQIcon.Svg icon="photo-film__r" />}
          >
            <AppFormattedMessage
              defaultMessage="Resource Library Report"
              description="[AppSidebar.Reports] Resource Library Report item label"
              id="app.sidebar.reports.resource.library.report"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper
          features={{ value: [FeatureTypeEnum.MentorFlix] }}
          permissions={{ value: [PermissionsEnum.ViewMentorFlixReport] }}
          strict
        >
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-mentor-flix-report-link"
            to={ROUTES.mentorFlixReport.data.path}
            prefix={<MQIcon.Svg icon="film__r" />}
          >
            <AppFormattedMessage
              defaultMessage="MentorFlix Report"
              description="[AppSidebar.Reports] MentorFlix Report item label"
              id="app.sidebar.reports.mentorflix.report"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper
          permissions={{
            value: [PermissionsEnum.ViewContentHelpdeskReport],
          }}
          features={{ value: [FeatureTypeEnum.ContentHelpdesk] }}
          strict
        >
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-content-helpdesk-report-link"
            to={ROUTES.helpdeskReport.data.path}
            prefix={<MQIcon.Svg icon="cabinet-filing__r" />}
          >
            <AppFormattedMessage
              defaultMessage="Content Helpdesk Report"
              description="[AppSidebar.Reports] Content Helpdesk Report item label"
              id="app.sidebar.reports.content.helpdesk.report"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper permissions={{ value: [PermissionsEnum.ViewProgramLogs] }}>
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-program-logs-report"
            to={ROUTES.programLogsReport.data.path}
            prefix={<MQIcon.Svg icon="rectangle-list__r" />}
          >
            <AppFormattedMessage
              defaultMessage="Program Logs"
              description="[Sidebar] Menu Item Name"
              id="app.sidebar.reports.program.logs"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
        <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageCliqAi] }}>
          <MQSidebar.ListItem
            as={AppLink}
            className="app-sidebar__list-item"
            data-testid="sidebar-cliq-ai-logs-report"
            to={ROUTES.cliqAiLogsReport.data.path}
            prefix={<MQIcon.Svg icon="sparkles__r" />}
          >
            <AppFormattedMessage
              defaultMessage="CliQ AI Logs"
              description="[AppSidebar.Reports] CliQ AI Logs item label"
              id="app.sidebar.reports.cliq.ai.logs"
            />
          </MQSidebar.ListItem>
        </PermissionWrapper>
      </MQSidebar.List>
    </MQSidebar.ListItem>
  </MQSidebar.List>
);
export default Reports;
