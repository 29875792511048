import './style.scss';

import { FC, useMemo } from 'react';

import { generateAvatar, MQ_AVATAR_SIZES, MQImage } from '@mentorcliq/ui';
import classNames from 'classnames';

import { MQAvatarImageProps } from '../../types';

const MQAvatarImage: FC<MQAvatarImageProps> = ({
  size = 'md',
  imageUrl,
  title,
  className,
  dataTestId = 'mq-image',
  borderless = false,
  ...props
}) => {
  const generatedAvatar = useMemo(() => generateAvatar(title || 'A Z'), [title]);

  return (
    <MQImage
      dataTestId={dataTestId}
      {...props}
      width={MQ_AVATAR_SIZES[size].width}
      height={MQ_AVATAR_SIZES[size].height}
      src={imageUrl}
      alt={`Avatar for ${title}`}
      style={{
        borderWidth: !borderless && imageUrl ? MQ_AVATAR_SIZES[size].borderWidth : 0,
        width: MQ_AVATAR_SIZES[size].width,
        height: MQ_AVATAR_SIZES[size].height,
      }}
      defaultImage={generatedAvatar}
      className={classNames(className, size, 'mq-avatar-img')}
      stretched
      cover
    />
  );
};

export default MQAvatarImage;
