import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

import { getAuthUserSelector, refreshTokenActivityThunk } from '@mentorcliq/storage';

import { useAppConfigs } from 'hooks/useAppConfigs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

type ActivityProviderProps = {
  timeout: number;
  refreshToken: string;
  children?: ReactNode;
};

const ActivityProvider: FC<ActivityProviderProps> = ({ timeout, refreshToken, children }) => {
  const dispatch = useAppDispatch();

  const activityRef = useRef<{ timerId: number; lastActivity: number }>({
    timerId: 0,
    lastActivity: 0,
  });

  useEffect(() => {
    activityRef.current.timerId = window.setInterval(() => {
      if (activityRef.current.lastActivity > 0) {
        dispatch(refreshTokenActivityThunk(refreshToken));
        activityRef.current.lastActivity = 0;
      }
    }, timeout);
    const intervalTimerId = activityRef.current.timerId;
    return () => {
      window.clearInterval(intervalTimerId);
    };
  }, [dispatch, timeout, refreshToken]);

  useEffect(() => {
    const handleClick = (event: MouseEvent | KeyboardEvent) => {
      const target = event.target;

      if (target) {
        activityRef.current.lastActivity = new Date().getTime();
      }
    };

    const onScroll = () => {
      activityRef.current.lastActivity = new Date().getTime();
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('keydown', handleClick);
    document.addEventListener('scroll', onScroll, true);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('keydown', handleClick);
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return <>{children}</>;
};

interface ActivityWrapperProps {
  children?: ReactNode;
}

const ActivityWrapper: FC<ActivityWrapperProps> = ({ children }) => {
  const { privateConfigs } = useAppConfigs();
  const authUser = useAppSelector(({ auth }) => getAuthUserSelector(auth));

  const timeout = useMemo(() => {
    if (privateConfigs.sessionInactivityTimeout) {
      return Math.ceil(privateConfigs.sessionInactivityTimeout / 10) * 60000;
    }
  }, [privateConfigs.sessionInactivityTimeout]);

  if (authUser?.id && timeout) {
    return (
      <ActivityProvider refreshToken={authUser.refreshToken} timeout={timeout}>
        {children}
      </ActivityProvider>
    );
  }

  return <>{children}</>;
};

export default ActivityWrapper;
