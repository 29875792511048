import { FC, ReactNode, useMemo } from 'react';

import { getIdMappingsThunk, getIdMappingsSelector } from '@mentorcliq/storage';
import { format, subMonths } from 'date-fns';

import ROUTES from 'routes';

import { LOCALE_DATE_FORMAT } from 'definitions/configs';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppMount } from 'hooks/useAppMount';
import { useAppParams } from 'hooks/useAppParams';
import { useAppQuery } from 'hooks/useAppQuery';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppSelector } from 'hooks/useAppSelector';

import AppRedirect from 'router/AppRedirect';

const eventsMapping = [
  { newName: 'MATCH_ENDED', oldName: 'matching' },
  { newName: 'MATCH_STARTED', oldName: 'matching' },
  { newName: 'MILESTONE', oldName: 'milestone' },
  { newName: 'QUICKCLIQ', oldName: 'quickcliq' },
  { newName: 'PROGRAM_SURVEY', oldName: 'survey' },
  { newName: 'RELATIONSHIP_SURVEY', oldName: 'survey' },
  { newName: 'GOAL', oldName: 'goal' },
];

const learningLabTypesMappings = [
  { newName: 'mentor-lab', oldName: 'mentorlab' },
  { newName: 'inclusion-lab', oldName: 'inclusionlab' },
  { newName: 'leadership-lab', oldName: 'leadershiplab' },
];

const learningLabSessionsMappings = [
  { newName: 'quick-start', oldName: 'quickstart' },
  { newName: 'master-class', oldName: 'masterclass' },
];

const dateRangeMapping = [
  {
    newName: 'LAST_1_MONTH',
    oldName: '-30 days',
    dateRangeTo: format(new Date(), LOCALE_DATE_FORMAT),
    dateRangeFrom: format(subMonths(new Date(), 1), LOCALE_DATE_FORMAT),
  },
  {
    newName: 'LAST_3_MONTHS',
    oldName: '-3 months',
    dateRangeTo: format(new Date(), LOCALE_DATE_FORMAT),
    dateRangeFrom: format(subMonths(new Date(), 3), LOCALE_DATE_FORMAT),
  },
  {
    newName: 'LAST_6_MONTHS',
    oldName: '-6 months',
    dateRangeTo: format(new Date(), LOCALE_DATE_FORMAT),
    dateRangeFrom: format(subMonths(new Date(), 6), LOCALE_DATE_FORMAT),
  },
  {
    newName: 'LAST_12_MONTHS',
    oldName: '-12 months',
    dateRangeTo: format(new Date(), LOCALE_DATE_FORMAT),
    dateRangeFrom: format(subMonths(new Date(), 12), LOCALE_DATE_FORMAT),
  },
];

interface AppBridgeProps {
  children?: ReactNode;
}

const AppBridge: FC<AppBridgeProps> = ({ children }) => {
  const { route, location } = useAppRouter();
  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return {
      filterStatus: searchParams.getAll('filter-status[]'),
      filterEvent: searchParams.getAll('filter-event[]'),
      filterProgram: searchParams.getAll('filter-program[]'),
      filterRelationship: searchParams.getAll('filter-relationship[]'),
    };
  }, [location.search]);

  const { qq_request_nid, 'date-range-preset': dateRangePreset, fd, td, modal } = useAppQuery();

  const {
    oldUserId,
    timestamp,
    oldProgramId,
    oldEnrollmentId1,
    oldEnrollmentId2,
    oldGroupId,
    encryptedOldQQRequestId,
    encryptedOldLeaveData,
    encryptedOldSurveyRequestId,
    encryptedOldSatisfactionSurveyRequestId,
    encryptedOldGroupId,
    encryptedOldEnrollmentId,
    programAlias: programAliasFromParams,
    token: tokenFromUrl,
    programId: srProgramId,
    filename: fileNameFromParams,
    oldLabType,
    oldSessionType,
  } = useAppParams();

  const ids = useAppSelector(({ idMappings }) => getIdMappingsSelector(idMappings));

  const dispatch = useAppDispatch();

  useAppMount(() => {
    dispatch(
      getIdMappingsThunk({
        ...(oldUserId && { userId: Number(oldUserId) }),
        ...(oldProgramId && { programId: Number(oldProgramId) }),
        ...(queryParams.filterProgram && { timelineProgramIds: queryParams.filterProgram.map((item) => Number(item)) }),
        ...(queryParams.filterRelationship && {
          timelineMatchIds: queryParams.filterRelationship.map((item) => Number(item)),
        }),
        ...(encryptedOldQQRequestId && { encryptedQQRequest: encryptedOldQQRequestId }),
        ...(qq_request_nid && { encryptedQQRequest: qq_request_nid }),
        ...(encryptedOldLeaveData && { encryptedLeaveRequest: encryptedOldLeaveData }),
        ...(oldGroupId && { matchId: Number(oldGroupId) }),
        ...(oldEnrollmentId1 && { enrollmentIdFirst: Number(oldEnrollmentId1.split('_')[0]) }),
        ...(oldEnrollmentId2 && { enrollmentIdSecond: Number(oldEnrollmentId2.split('_')[0]) }),
        ...(encryptedOldGroupId && { encryptedMatch: encryptedOldGroupId }),
        ...(encryptedOldEnrollmentId && { encryptedEnrollment: encryptedOldEnrollmentId }),
        ...(programAliasFromParams && { programAlias: programAliasFromParams }),
        ...(tokenFromUrl && { token: tokenFromUrl }),
        ...(timestamp && { passwordResetTimestamp: Number(timestamp) }),
        ...(encryptedOldSurveyRequestId && {
          encryptedCustomSurveyRequest: encryptedOldSurveyRequestId,
        }),
        ...(encryptedOldSatisfactionSurveyRequestId && {
          encryptedSatisfactionSurveyRequest: encryptedOldSatisfactionSurveyRequestId,
        }),
        ...(fileNameFromParams && {
          fileName: fileNameFromParams,
        }),
        ...(modal && {
          videoResourceModalName: modal,
        }),
      }),
    );
  });

  const timelineRoute = useMemo(() => {
    if (
      (!queryParams.filterProgram.length || ids?.timelineProgramIds?.length) &&
      (!queryParams.filterRelationship.length || ids?.timelineMatchIds?.length)
    ) {
      const newDate = dateRangeMapping.find((item) => item.oldName === dateRangePreset);
      if (ids?.userId) {
        return ROUTES.timeline.convert({
          search: {
            events: 'MILESTONE',
            participantId: ids.userId,
          },
        });
      }
      return ROUTES.timeline.convert({
        search: {
          statuses: queryParams.filterStatus.map((item) => item.toLocaleUpperCase()).join(','),
          ...(ids?.timelineProgramIds?.length && { programIds: ids.timelineProgramIds.join(',') }),
          ...(ids?.timelineMatchIds?.length && { matchFilterMatchIds: ids.timelineMatchIds.join(',') }),
          events: eventsMapping
            .filter((item) => queryParams.filterEvent.includes(item.oldName))
            .map((item) => item.newName)
            .join(','),
          ...(newDate && {
            dateRangeValue: newDate.newName,
            dateRangeFrom: newDate.dateRangeFrom,
            dateRangeTo: newDate.dateRangeTo,
          }),
        },
      });
    }
  }, [
    dateRangePreset,
    queryParams.filterEvent,
    queryParams.filterProgram.length,
    queryParams.filterRelationship.length,
    queryParams.filterStatus,
    ids?.timelineMatchIds,
    ids?.timelineProgramIds,
    ids?.userId,
  ]);

  const surveyRequestsRoute = useMemo(() => {
    if (ids?.encryptedSatisfactionSurveyRequest || ids?.encryptedCustomSurveyRequest) {
      return ROUTES.surveyRequests.convert({
        path: {
          token: ids?.encryptedSatisfactionSurveyRequest ?? ids?.encryptedCustomSurveyRequest,
        },
      });
    }
  }, [ids?.encryptedCustomSurveyRequest, ids?.encryptedSatisfactionSurveyRequest]);

  const createPasswordRoute = useMemo(() => {
    if (ids?.token && ids?.passwordResetTimestamp && ids?.userId && ids?.userEmail) {
      return ROUTES.createPassword.convert({
        search: {
          userId: ids.userId,
          token: ids.token,
          user_email: ids.userEmail,
          time_stamp: ids.passwordResetTimestamp,
        },
      });
    }
  }, [ids?.passwordResetTimestamp, ids?.token, ids?.userEmail, ids?.userId]);

  const developmentReportPersonal = useMemo(() => {
    if (ids?.userId) {
      return ROUTES.developmentReportsPersonal.convert({
        search: {
          participantId: ids.userId,
          ...(fd && { startDate: format(new Date(fd), LOCALE_DATE_FORMAT) }),
          ...(td && { endDate: format(new Date(td), LOCALE_DATE_FORMAT) }),
        },
      });
    }
  }, [fd, td, ids?.userId]);

  const resourceLibraryProgram = useMemo(() => {
    if (ids?.programName) {
      return ROUTES.resourceLibraryProgram.convert({
        search: {
          programs: ids.programName,
        },
      });
    } else if (ids?.videoResourceModalId) {
      if (modal.includes('alt-stream-resource-')) {
        return ROUTES.resourceLibraryProgram.convert({
          search: {
            videoId: ids.videoResourceModalId,
            videoToShow: 'alternative',
          },
        });
      } else if (modal.includes('alt-resource-')) {
        return ROUTES.resourceLibraryProgram.convert({
          search: {
            videoId: ids.videoResourceModalId,
            videoToShow: 'accessible',
          },
        });
      } else if (modal.includes('resource-')) {
        return ROUTES.resourceLibraryProgram.convert({
          search: {
            videoId: ids.videoResourceModalId,
            videoToShow: 'main',
          },
        });
      }
    }
  }, [ids?.programName, modal, ids?.videoResourceModalId]);

  const resourceLibraryGlobalModalRoute = useMemo(() => {
    if (ids?.videoResourceModalId) {
      if (modal.includes('alt-stream-resource-')) {
        return ROUTES.resourceLibraryGlobal.convert({
          search: {
            videoId: ids.videoResourceModalId,
            videoToShow: 'alternative',
          },
        });
      } else if (modal.includes('alt-resource-')) {
        return ROUTES.resourceLibraryGlobal.convert({
          search: {
            videoId: ids.videoResourceModalId,
            videoToShow: 'accessible',
          },
        });
      } else if (modal.includes('resource-')) {
        return ROUTES.resourceLibraryGlobal.convert({
          search: {
            videoId: ids.videoResourceModalId,
            videoToShow: 'main',
          },
        });
      }
    }
  }, [modal, ids?.videoResourceModalId]);

  const matchComparison = useMemo(() => {
    if (ids?.encryptedMatch) {
      return ROUTES.matchComparison.convert({
        path: {
          token: ids.encryptedMatch,
        },
      });
    }
  }, [ids?.encryptedMatch]);

  const quickcliQRequestsEncrypted = useMemo(() => {
    if (ids?.encryptedQQRequest) {
      return ROUTES.quickcliQRequestsEncrypted.convert({
        path: {
          token: ids.encryptedQQRequest,
        },
      });
    }
  }, [ids?.encryptedQQRequest]);

  const enrollmentsRequestsEncrypted = useMemo(() => {
    if (ids?.encryptedEnrollment) {
      return ROUTES.enrollmentsRequestsEncrypted.convert({
        path: {
          token: ids.encryptedEnrollment,
        },
      });
    }
  }, [ids?.encryptedEnrollment]);

  const nudgeLeaveRelationship = useMemo(() => {
    if (ids?.encryptedLeaveRequest) {
      return ROUTES.nudgeLeaveRelationship.convert({
        path: {
          token: ids.encryptedLeaveRequest,
        },
      });
    }
  }, [ids?.encryptedLeaveRequest]);

  const acceptMatchRequest = useMemo(() => {
    if (ids?.encryptedMatch) {
      return ROUTES.acceptMatchRequest.convert({
        path: {
          token: ids.encryptedMatch,
        },
      });
    }
  }, [ids?.encryptedMatch]);

  const declineMatchRequest = useMemo(() => {
    if (ids?.encryptedMatch) {
      return ROUTES.declineMatchRequest.convert({
        path: {
          token: ids.encryptedMatch,
        },
      });
    }
  }, [ids?.encryptedMatch]);

  const enrollmentApprovalRequest = useMemo(() => {
    if (ids?.encryptedEnrollment) {
      return ROUTES.enrollmentApprovalRequest.convert({
        path: {
          token: ids.encryptedEnrollment,
        },
      });
    }
  }, [ids?.encryptedEnrollment]);

  const programs = useMemo(() => {
    if (ids?.userId) {
      return ROUTES.programs.convert({
        search: {
          categoryId: -1,
          participantId: ids.userId,
        },
      });
    }
  }, [ids?.userId]);

  const learningLabLive = useMemo(
    () =>
      ROUTES.learningLabLive.convert({
        path: {
          lab: learningLabTypesMappings.find((el) => el.oldName === oldLabType)?.newName,
          type: learningLabSessionsMappings.find((el) => el.oldName === oldSessionType)?.newName,
        },
      }),
    [oldLabType, oldSessionType],
  );

  const enrollWizardWelcome = useMemo(() => {
    if (srProgramId) {
      return ROUTES.enrollWizardWelcome.convert({
        path: {
          programId: srProgramId,
        },
      });
    } else if (ids?.programId) {
      return ROUTES.enrollWizardWelcome.convert({
        path: {
          programId: ids.programId,
        },
      });
    }
  }, [ids?.programId, srProgramId]);

  const developmentReportOthers = useMemo(() => {
    if (ids?.userId) {
      return ROUTES.developmentReportsOthers.convert({
        search: {
          participantId: ids.userId,
          ...(fd && { startDate: format(new Date(fd), LOCALE_DATE_FORMAT) }),
          ...(td && { endDate: format(new Date(td), LOCALE_DATE_FORMAT) }),
        },
      });
    }
  }, [fd, td, ids?.userId]);

  const redirectRoute = useMemo(() => {
    if (ids) {
      if (route.redirectTo?.bucket && fileNameFromParams) {
        if (ids.resourceLibraryFileId) {
          return ROUTES.resourceLibraryFile.convert({
            path: {
              fileId: ids.resourceLibraryFileId,
              fileName: fileNameFromParams,
            },
          });
        } else if (ids?.bucketName) {
          window.open(`${route.redirectTo.path}${ids?.bucketName}/${ids.fileName}`, '_blank');
        }
        return ROUTES.userDashboard.data.path;
      } else {
        switch (route.redirectTo?.path) {
          case ROUTES.login.data.path:
          case ROUTES.myCliQ.data.path:
          case ROUTES.mentorLab.data.path:
          case ROUTES.inclusionLab.data.path:
          case ROUTES.leadershipLab.data.path:
          case ROUTES.labsList.data.path:
          case ROUTES.mainPage.data.path:
          case ROUTES.redirectEnrollPreferences.data.path:
            return route.redirectTo.path;
          case ROUTES.createPassword.data.path:
            return createPasswordRoute;
          case ROUTES.matchComparison.data.path:
            return matchComparison;
          case ROUTES.quickcliQRequests.data.path:
            return quickcliQRequestsEncrypted;
          case ROUTES.timeline.data.path:
            return timelineRoute;
          case ROUTES.surveyRequests.data.path:
            return surveyRequestsRoute;
          case ROUTES.enrollmentsRequestsEncrypted.data.path:
            return enrollmentsRequestsEncrypted;
          case ROUTES.acceptMatchRequest.data.path:
            return acceptMatchRequest;
          case ROUTES.declineMatchRequest.data.path:
            return declineMatchRequest;
          case ROUTES.enrollmentApprovalRequest.data.path:
            return enrollmentApprovalRequest;
          case ROUTES.programs.data.path:
            return programs;
          case ROUTES.enrollWizardWelcome.data.path:
            return enrollWizardWelcome;
          case ROUTES.developmentReportsPersonal.data.path:
            return developmentReportPersonal;
          case ROUTES.developmentReportsOthers.data.path:
            return developmentReportOthers;
          case ROUTES.nudgeLeaveRelationship.data.path:
            return nudgeLeaveRelationship;
          case ROUTES.learningLabLive.data.path:
            return learningLabLive;
          case ROUTES.resourceLibraryGlobal.data.path:
            return resourceLibraryGlobalModalRoute;
          case ROUTES.resourceLibraryProgram.data.path:
            return resourceLibraryProgram;
        }
      }
    }
    return null;
  }, [
    ids,
    route.redirectTo?.bucket,
    route.redirectTo?.path,
    fileNameFromParams,
    createPasswordRoute,
    matchComparison,
    quickcliQRequestsEncrypted,
    timelineRoute,
    surveyRequestsRoute,
    enrollmentsRequestsEncrypted,
    acceptMatchRequest,
    declineMatchRequest,
    enrollmentApprovalRequest,
    programs,
    enrollWizardWelcome,
    developmentReportPersonal,
    developmentReportOthers,
    nudgeLeaveRelationship,
    learningLabLive,
    resourceLibraryGlobalModalRoute,
    resourceLibraryProgram,
  ]);

  if (redirectRoute) {
    return <AppRedirect to={redirectRoute} />;
  }

  if (ids && !redirectRoute) {
    return children;
  }
};

export default AppBridge;
