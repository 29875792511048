import {
  InvitationStyleEnum,
  ParticipantMatchingStyle,
  ProgramRequestDto,
  ProgramResponseDto,
  ProgramRoleDto,
  RoleEnrollmentInfoDto,
  RoleTypesEnum,
} from '@mentorcliq/storage';

export interface SortRoleByType {
  data: any;
  programRole: ProgramRoleDto;
}

export const sortRolesByType = (programRoles: ProgramRoleDto[]): ProgramRoleDto[] =>
  Object.values(RoleTypesEnum).flatMap((type) =>
    programRoles.filter(({ programRoleType }) => programRoleType === type),
  );

export const sortEnrollmentsByType = (enrollments: RoleEnrollmentInfoDto[]): RoleEnrollmentInfoDto[] =>
  Object.keys(RoleTypesEnum).flatMap((programRoleType) =>
    enrollments.filter(({ programRole }) => programRole.programRoleType === programRoleType),
  );

export const sortDataByRole = (data: SortRoleByType[]) => {
  const orderedData = data.toSorted((a, b) => a.programRole.name.localeCompare(b.programRole.name));

  return Object.keys(RoleTypesEnum).flatMap((programRoleType) =>
    orderedData.filter((item) => item.programRole.programRoleType === programRoleType),
  );
};

export const sortRolesByRoleIds = <
  T extends {
    programRoleId: number;
    [key: string]: any;
  },
>(
  programRoles: ProgramRoleDto[],
  items: T[],
): T[] => {
  const roleIds = sortRolesByType(programRoles).map((role) => role.id);
  return [...items].sort((prev, next) => roleIds.indexOf(prev.programRoleId) - roleIds.indexOf(next.programRoleId));
};

export const serializeProgramResponse = (request: ProgramRequestDto): ProgramResponseDto => ({
  ...request,
  id: request.id ?? -1,
  name: request.name ?? '',
  description: request.description ?? '',
  demo: false,
  retired: false,
  programCategory: request.programCategory ?? {
    id: -1,
    name: '',
  },
  programPillar: request.programPillar ?? {
    id: -1,
    name: '',
  },
  invitationStyleConfig: request.invitationStyleConfig ?? {
    id: -1,
    invitationStyle: InvitationStyleEnum.Public,
    openStyleExcludedRoleIds: [],
  },
  relationshipLength: request.relationshipLength ?? 0,
  allowManageRelationship: !!request.allowManageRelationship,
  matchingStyle: request.matchingStyle ?? ParticipantMatchingStyle.OneToOne,
  showProgramDetails: !!request.showProgramDetails,
  programRoles: request.programRoles ?? [],
  matchConfigurations: request.matchConfigurations ?? [],
  enrollmentPreferenceConfig: request.enrollmentPreferenceConfig ?? {
    questions: [],
  },
  preferencesDisplayConfigs: request.preferencesDisplayConfigs ?? [],
  goalsEnabled: !!request.goalsEnabled,
});
