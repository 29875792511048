import { FC, ReactNode, useState } from 'react';

import { MQImage, MQIcon } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { STATIC_ASSETS } from 'definitions/assets';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import history from 'helpers/history';

import { useAppParams } from 'hooks/useAppParams';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppViewAs } from 'hooks/useAppViewAs';

import MQButton from 'modules/MQButton';
import MQGrid from 'modules/MQGrid';
import MQLayout from 'modules/MQLayout';
import MQModal from 'modules/MQModal';
import MQTypo from 'modules/MQTypo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

interface ApiTimeoutModalProps {
  title?: ReactNode;
  description?: ReactNode;
}

const CUSTOM_PAGE_PATHS = {
  wizardCreate: ROUTES.programWizardDraftCreate.data.path,
  wizardEdit: ROUTES.programWizardDraftEdit.data.path,
};

const ApiTimeoutModal: FC<ApiTimeoutModalProps> = ({ title, description }) => {
  const [showModal, setShowModal] = useState(true);
  const { viewingUserId } = useAppViewAs();
  const { programId } = useAppParams();
  const { route } = useAppRouter();

  const handleRefreshPage = () => {
    setShowModal(false);

    if (Object.values(CUSTOM_PAGE_PATHS).includes(route.data.path)) {
      if (route.data.path.includes(CUSTOM_PAGE_PATHS.wizardCreate)) {
        window.location.href = history.mq.createHref({
          pathname: ROUTES.adminDashboard.data.path,
        });

        return;
      } else if (route.data.path.includes(CUSTOM_PAGE_PATHS.wizardEdit)) {
        window.location.href = history.mq.createHref({
          pathname: ROUTES.programDashboard.convert({ path: { programId } }),
        });
        return;
      }
    }
    window.location.reload();
  };

  return (
    <MQModal show={showModal} hideOnBackdropClick={false} closableKeys={[]} blurry>
      <MQModal.Body>
        <MQGrid.FlexBox direction="column" align="center">
          <MQLayout.Heading center>
            <MQTypo.Title type="h2" center bold>
              {title ?? <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalTimeoutErrorTitle} />}
            </MQTypo.Title>
          </MQLayout.Heading>
          <MQTypo.Text size="5x" center>
            {description ?? <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalTimeoutErrorDescription} />}
          </MQTypo.Text>
          <MQImage src={STATIC_ASSETS.images.errors.image429} width={220} />
        </MQGrid.FlexBox>
      </MQModal.Body>
      <MQModal.Footer>
        <MQButton
          dataTestId="api-timeout-modal-back-to-my-cliq-button"
          variant="secondary"
          onClick={() => {
            setShowModal(false);
            window.location.href = history.mq.createHref({
              pathname: ROUTES.myCliQ.convert({
                search: {
                  participantId: viewingUserId,
                },
              }),
            });
          }}
          startIcon={<MQIcon.Svg icon="arrow-left" />}
        >
          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalBackToMyCliQ} />
        </MQButton>
        <MQButton
          dataTestId="api-timeout-modal-refresh-page-button"
          onClick={handleRefreshPage}
          startIcon={<MQIcon.Svg icon="rotate" />}
        >
          <AppFormattedMessage
            defaultMessage="Refresh Page"
            description="[ApiTimeoutModal] Refresh page button text"
            id="api.timeout.modal.refresh.page.button.text"
          />
        </MQButton>
      </MQModal.Footer>
    </MQModal>
  );
};

export default ApiTimeoutModal;
