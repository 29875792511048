import './style.scss';

import { FC } from 'react';

import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import MQSidebar from 'modules/MQSidebar';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

import Programs from './Addons/components/Programs';

const Dashboard: FC = () => (
  <MQSidebar.List className="app-sidebar__list" data-testid="sidebar-dashboard">
    <MQSidebar.ListItem
      as={AppLink}
      className="app-sidebar__list-item"
      data-testid="sidebar-dashboard-link"
      to={ROUTES.adminDashboard.data.path}
      prefix={<MQIcon.Svg icon="house__r" />}
    >
      <AppFormattedMessage {...APP_GLOBAL_MESSAGES.adminDashboard} />
    </MQSidebar.ListItem>
    <PermissionWrapper permissions={{ value: [PermissionsEnum.AddAProgram] }}>
      <MQSidebar.ListItem
        as={AppLink}
        className="app-sidebar__list-item"
        data-testid="sidebar-program-wizard-link"
        to={ROUTES.programWizardWelcome.data.path}
        prefix={<MQIcon.Svg icon="draw-circle__l" />}
      >
        <AppFormattedMessage
          defaultMessage="Design New Program"
          description="[AppSidebar.Dashboard] Design New Program label"
          id="app.sidebar.dashboard.design.new.program"
        />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <PermissionWrapper permissions={{ value: [PermissionsEnum.ViewRhr] }}>
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-reports-link"
        to={ROUTES.reports.data.path}
        prefix={<MQIcon.Svg icon="chart-line-up__r" />}
        overlay={<MQIcon.Svg icon="arrow-circle-right" />}
      >
        <AppFormattedMessage {...APP_GLOBAL_MESSAGES.userDashBoardReportsTabLabel} />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageCustomerUsers] }}>
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-people-link"
        to={ROUTES.people.data.path}
        prefix={<MQIcon.Svg icon="user-group__r" />}
        overlay={<MQIcon.Svg icon="arrow-circle-right" />}
      >
        <AppFormattedMessage {...APP_GLOBAL_MESSAGES.people} />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <PermissionWrapper
      permissions={{
        value: [
          PermissionsEnum.ManagePlatformInfo,
          PermissionsEnum.ViewAddOns,
          PermissionsEnum.UpdateAddOns,
          PermissionsEnum.MentorcliqBranding,
          PermissionsEnum.ManagePlatformResources,
          PermissionsEnum.ManagePlatformConfiguration,
          PermissionsEnum.ManageFeatures,
          PermissionsEnum.ManageDynamicTranslations,
          PermissionsEnum.ManageBaseTranslations,
          PermissionsEnum.ManageProfileSettings,
          PermissionsEnum.ManageProfile,
          PermissionsEnum.ManageHRIS,
          PermissionsEnum.ManageSSOSettings,
          PermissionsEnum.ManageCalendarConfiguration,
          PermissionsEnum.ManageZoomConfiguration,
          PermissionsEnum.ManageCollaborationTools,
        ],
      }}
    >
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-configure-link"
        to={ROUTES.configure.data.path}
        prefix={<MQIcon.Svg icon="sliders" />}
        overlay={<MQIcon.Svg icon="arrow-circle-right" />}
      >
        <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformSettingsTitle} />
      </MQSidebar.ListItem>
    </PermissionWrapper>
    <Programs />
    <PermissionWrapper
      permissions={{ value: [PermissionsEnum.ManageContentHelpdeskData, PermissionsEnum.ManageMentorFlixContent] }}
    >
      <MQSidebar.ListItem>
        <MQSidebar.List>
          <MQSidebar.ListItem>
            <MQSidebar.Heading className="app-sidebar__heading">
              <AppFormattedMessage
                defaultMessage="Admin Resources"
                description="[AppSidebar.Dashboard] Admin Resources label"
                id="app.sidebar.dashboard.admin.dahsboard"
              />
            </MQSidebar.Heading>
          </MQSidebar.ListItem>
          <PermissionWrapper
            permissions={{ value: [PermissionsEnum.ManageContentHelpdeskData] }}
            features={{ value: [FeatureTypeEnum.ContentHelpdesk] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-helpdesk-link"
              to={ROUTES.helpdesk.data.path}
              prefix={<MQIcon.Svg icon="cabinet-filing__r" />}
            >
              <AppFormattedMessage {...APP_GLOBAL_MESSAGES.contentHelpdesk} />
            </MQSidebar.ListItem>
          </PermissionWrapper>
          <PermissionWrapper
            features={{ value: [FeatureTypeEnum.MentorFlix] }}
            permissions={{ value: [PermissionsEnum.ManageMentorFlixContent] }}
            strict
          >
            <MQSidebar.ListItem
              as={AppLink}
              className="app-sidebar__list-item"
              data-testid="sidebar-mentorFlix-link"
              to={ROUTES.mentorFlix.data.path}
              prefix={<MQIcon.Svg icon="film__r" />}
            >
              <AppFormattedMessage
                defaultMessage="MentorFlix"
                description="[AppSidebar.Dashboard] MentorFlix label"
                id="app.sidebar.dashboard.mentorflix"
              />
            </MQSidebar.ListItem>
          </PermissionWrapper>
        </MQSidebar.List>
      </MQSidebar.ListItem>
    </PermissionWrapper>
  </MQSidebar.List>
);

export default Dashboard;
