import React, { FC, ReactNode, useMemo } from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { PRIVATE_ARGS } from 'definitions/bugSnag';
import { APP_ENV_CONFIGS } from 'definitions/configs';

type ErrorProviderProps = {
  children: ReactNode;
  production?: boolean;
};

const ErrorProvider: FC<ErrorProviderProps> = ({ children, production = APP_ENV_CONFIGS.environments.production }) => {
  const ErrorBoundary = useMemo(() => {
    if (production) {
      try {
        const client = Bugsnag.start({
          apiKey: APP_ENV_CONFIGS.bugSnagApiKey || '',
          plugins: [new BugsnagPluginReact(React)],
          releaseStage: `${APP_ENV_CONFIGS.projectId}_${APP_ENV_CONFIGS.projectEnv}`,
          appVersion: APP_ENV_CONFIGS.version,
          collectUserIp: false,
          redactedKeys: PRIVATE_ARGS,
        });

        return client?.getPlugin('react')?.createErrorBoundary(React);
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }, [production]);

  if (ErrorBoundary) {
    return (
      <ErrorBoundary
        onError={(e) => {
          console.error(e);
        }}
      >
        {children}
      </ErrorBoundary>
    );
  }

  return <>{children}</>;
};

export default ErrorProvider;
