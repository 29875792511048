import './style.scss';

import { FC, useEffect, useState } from 'react';

import { random } from 'lodash';

interface MQLoaderThinkingProps {
  messages: string[];
}

const MQLoaderThinking: FC<MQLoaderThinkingProps> = ({ messages }) => {
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(Math.floor(Math.random() * messages.length));
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const typeMessage = () => {
      const message = messages[currentMessageIndex];

      let index = 0;
      setDisplayedMessage(' ');
      const typingInterval = setInterval(() => {
        setDisplayedMessage(message.slice(0, index + 1));

        if (index >= message.length - 1) {
          clearInterval(typingInterval);
          setIsTyping(false);
        }

        index = (index + 1) % (message.length + 1);
      }, 50);

      return typingInterval;
    };

    const typingInterval = typeMessage();

    return () => clearInterval(typingInterval);
  }, [currentMessageIndex, messages]);

  useEffect(() => {
    if (!isTyping) {
      const messageChangeTimeout = setTimeout(
        () => {
          setCurrentMessageIndex((prevState) => {
            let currentIndex = prevState;

            while (currentIndex === prevState) {
              currentIndex = Math.floor(Math.random() * messages.length);
            }

            return currentIndex;
          });
          setIsTyping(true);
        },
        random(500, 1500),
      );

      return () => clearTimeout(messageChangeTimeout);
    }
  }, [isTyping, messages.length]);

  return (
    <div className="mq-loader-thinking">
      <div className="mq-loader-thinking__text">{displayedMessage}</div>
    </div>
  );
};

export default MQLoaderThinking;
