import './style.scss';

import { FC, useEffect, useRef, useState } from 'react';

import {
  addUserMessageThunk,
  askCliqAiThunk,
  CliqAiMessageSenderEnum,
  getAIAnswerLoadingSelector,
  getAiMessagesSelector,
} from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppIntl } from 'hooks/useAppIntl';
import { useAppMount } from 'hooks/useAppMount';
import { useAppSelector } from 'hooks/useAppSelector';

import MQButton from 'modules/MQButton';
import MQScrollable from 'modules/MQScrollable';
import MQTypo from 'modules/MQTypo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppChat from 'layout/AppChat';

import ChatMessage from './Addons/components/ChatMessage';
import CliqAiLoadingMessage from './Addons/components/CliqAiLoadingMessage';

interface ChatWindowProps {
  back: () => void;
}

const ChatWindow: FC<ChatWindowProps> = ({ back }) => {
  const intl = useAppIntl();
  const dispatch = useAppDispatch();
  const bottomRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);
  const cliqAiMessages = useAppSelector(({ aiChat }) => getAiMessagesSelector(aiChat));
  const cliqAiLoading = useAppSelector(({ aiChat }) => getAIAnswerLoadingSelector(aiChat));
  const [resized, setResized] = useState(false);
  const [isScrollingBottom, setIsScrollingBottom] = useState(false);
  const [isScrollingTop, setIsScrollingTop] = useState(false);

  const handleScrollToBottom = (behavior: ScrollBehavior = 'auto') => {
    bottomRef.current?.scrollIntoView({
      behavior,
    });
  };

  useAppMount(() => {
    handleScrollToBottom();
  });

  useEffect(() => {
    if (isScrollingBottom && !isScrollingTop && cliqAiMessages.at(-1)?.message) {
      handleScrollToBottom();
    }
  }, [isScrollingBottom, isScrollingTop, cliqAiMessages]);

  useEffect(() => {
    if (cliqAiLoading.isPending) {
      handleScrollToBottom('smooth');
    }
  }, [cliqAiLoading.isPending]);

  const handleScroll = () => {
    const element = scrollRef.current;
    if (element) {
      const isBottom = element.scrollHeight - element.scrollTop - element.clientHeight <= 100;
      setIsScrollingBottom(isBottom);
    }
  };

  useEffect(() => {
    if (cliqAiMessages) {
      handleScroll();
    }
  }, [cliqAiMessages]);

  const handleScrollTop = () => {
    const element = scrollRef.current;

    if (element) {
      const currentScrollTop = element.scrollTop;
      if (currentScrollTop < lastScrollTop.current) {
        setIsScrollingTop(true);
      } else {
        setIsScrollingTop(false);
      }

      lastScrollTop.current = currentScrollTop;
    }
  };

  useEffect(() => {
    const element = scrollRef.current;

    if (element) {
      element.addEventListener('scroll', handleScrollTop);
      return () => element.removeEventListener('scroll', handleScrollTop);
    }
  }, [cliqAiMessages]);

  const askCliqAi = async (text: string) => {
    await dispatch(
      addUserMessageThunk({ sender: CliqAiMessageSenderEnum.User, created: Date.now().toString(), message: text }),
    );
    await dispatch(askCliqAiThunk({ text }));
  };

  return (
    <div className={classNames('chat-window', { resized })}>
      <div className="chat-window__header">
        <MQButton dataTestId="ai-chat-back-button" variant="icon" onClick={back}>
          <MQIcon.Svg size="lg" icon="arrow-left" />
        </MQButton>
        <MQTypo.Text bold size="3x" variant="indigo">
          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cliqAiLabel} />
        </MQTypo.Text>
        <MQButton dataTestId="ai-chat-resize-button" variant="icon" onClick={() => setResized(!resized)}>
          {resized ? <MQIcon.Svg size="lg" icon="expand" /> : <MQIcon.Svg size="lg" icon="compress" />}
        </MQButton>
      </div>

      <MQScrollable.Wrapper instance={scrollRef} className="chat-window__body" onScroll={handleScroll} height="100%">
        <ChatMessage
          sender={CliqAiMessageSenderEnum.CliqAi}
          message={intl.formatMessage({
            defaultMessage:
              "Hello! I'm your AI assistant, here to help you with MentorcliQ Platform. Whether you need information, assistance with tasks, or just have a question, I'm here to make things easier for you. Just type in what you need, and I'll do my best to provide a quick and helpful response. Let’s get started!",
            description: '[CliqAi] Chat welcome message',
            id: 'cliq.ai.chat.welcome.message',
          })}
        />
        {cliqAiMessages.map((item, index) => (
          <div key={item.created}>
            <ChatMessage sender={item.sender} created={item.created} message={item.message} />
            {index === cliqAiMessages.length - 1 && <div ref={bottomRef} />}
          </div>
        ))}
        {cliqAiLoading.isPending && cliqAiMessages.at(-1)?.sender !== CliqAiMessageSenderEnum.CliqAi && (
          <div ref={bottomRef}>
            <CliqAiLoadingMessage />
          </div>
        )}
      </MQScrollable.Wrapper>

      <div className="chat-window__footer">
        <AppChat.Toolbar
          sendMessage={({ message }) => {
            askCliqAi(message);
          }}
          disabled={cliqAiLoading.isPending}
          placeholder={intl.formatMessage({
            defaultMessage: 'Ask a question...',
            description: '[Chat] Message Input placeholder',
            id: 'chat.ask.question.input.placeholder',
          })}
        />
      </div>
    </div>
  );
};

export default ChatWindow;
